import { validateAlphabetOnly } from './helper';
import fieldValidationPatterns from './fieldValidationPatterns';
import phone from 'phone';

const MOBILE_ACCEPTED_COUNTRY_CODE = process.env.REACT_APP_ENV === 'dev' ? null : 'GB';
export const validateMobileNumber = (value) =>
  phone(value, { country: MOBILE_ACCEPTED_COUNTRY_CODE });

export const validationRules = {
  required: (fieldName) => ({ required: true, message: `Please input ${fieldName}.` }),

  email: (fieldName = 'email') => ({
    type: 'email',
    message: `The input is not a valid ${fieldName}.`,
  }),

  minLength: (fieldName = 'password', min = 8) => ({
    min: min,
    message: `${fieldName} must be at least ${min} characters long`,
  }),

  password:
    (fieldName = 'password') =>
    ({}) => ({
      validator(_, value) {
        if ((value && value.length < 8) || value.length === 0) {
          return Promise.resolve();
        }
        const errors = [];
        if (!/[a-z]/.test(value)) errors.push('one lowercase letter');
        if (!/[A-Z]/.test(value)) errors.push('one uppercase letter');
        if (!/\d/.test(value)) errors.push('one number');
        if (!/[^A-Za-z0-9 ]/.test(value)) errors.push('one special character');
        if (errors.length > 0) {
          return Promise.reject(
            ` Your password must contain at least ${errors.join(', and ')}. `
          );
        }
        return Promise.resolve();
      },
    }),

  phone: (fieldName = 'mobile number') => ({
    pattern: fieldValidationPatterns.phonePattern,
    message: `Please enter a valid 10-digit ${fieldName}.`,
  }),

  alphabetOnly: (fieldName) => ({
    validator: (_, value) =>
      value
        ? validateAlphabetOnly(value)
          ? Promise.resolve()
          : Promise.reject(`${fieldName} can only contain alphabets.`)
        : Promise.resolve(),
  }),
  numbersOnly: (fieldName) => ({
    pattern: new RegExp('^[0-9]*$'),
    message: `${fieldName} can only contain numbers`,
  }),
  confirmField:
    (fieldName, { refFieldName, refFieldLabel }) =>
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue(refFieldName) === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(`The ${fieldName} that you entered does not match the ${refFieldLabel}.`)
        );
      },
    }),
  mobileNumber: (fieldName) => ({
    validator: (_, value) => {
      if (!value) return Promise.resolve();
      if (!validateMobileNumber(value).isValid) {
        return Promise.reject(`Please enter valid ${fieldName}`);
      }
      return Promise.resolve();
    },
  }),
};
