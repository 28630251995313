const fieldValidationPatterns = {
  phonePattern: new RegExp('^\\+?[0-9]+$'),
  emailPattern: new RegExp('^\\S+@\\S+\\.\\S+$'), // Basic email pattern, you might want to use a more comprehensive one
  earningAmountPattern: new RegExp('^\\d+(\\.\\d{1,2})?$'), // Pattern for a number with up to 2 decimal places
  dateOfBirthPattern: new RegExp('^\\d{4}-\\d{2}-\\d{2}$'), // Assuming yyyy-mm-dd format
  accountNumberPattern: new RegExp('^[0-9]{8}$'),
  sortCodePattern: new RegExp('^\\d{6}$'),
  taxCodePattern: new RegExp('^[A-Z0-9]+$'),
  workNumberPattern: new RegExp('^[0-9a-zA-Z]+$'),
  nationalInsuranceNumberPattern: new RegExp('^[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\\d{6}[A-D\\s]$'),
  nationalInsuranceTablePattern: new RegExp('^.*$'), // Custom pattern, adjust as necessary
  positionPattern: new RegExp('^.*$'), // Custom pattern, adjust as necessary
  typePattern: new RegExp('^.*$'), // Custom pattern, adjust as necessary
};

export default fieldValidationPatterns;
