import React from 'react';
import {
  FaUserTie,
  FaBuilding,
  FaTicketAlt,
  FaRecycle,
  FaUsers,
  FaFileInvoice,
  FaDatabase,
  FaCogs,
} from 'react-icons/fa';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { BiTransfer } from 'react-icons/bi';
import { MdPerson } from 'react-icons/md';
import Icon from '@ant-design/icons';
import { IoNewspaper } from 'react-icons/io5';

// Employers /  employees / Payroll data // payroll period / deducation summary / user / advance
// Employer user with Role Admin
export const employerAdminMenuConfig = [
  {
    path: '/',
    icon: <Icon component={MdPerson} style={{ fontSize: 20 }} />,
    label: 'Employer',
    roles: ['ADMIN', 'USER'],
  },
  {
    path: '/employees',
    icon: <Icon component={FaUserTie} style={{ fontSize: 20 }} />,
    label: 'Employees',
    disabled: false,
    roles: ['ADMIN', 'USER'],
  },

  {
    path: '/transactions',
    icon: <Icon component={BiTransfer} style={{ fontSize: 20 }} />,
    label: 'Advances',
    disabled: false,
    roles: ['ADMIN', 'USER'],
  },

  {
    path: '/pay-periods',
    icon: <Icon component={FaRecycle} style={{ fontSize: 20 }} />,
    label: 'Pay Periods',
    disabled: false,
    roles: ['ADMIN', 'USER'],
  },

  {
    path: '/payroll-data',
    icon: <Icon component={FaDatabase} style={{ fontSize: 20 }} />,
    label: 'Payroll Data',
    roles: ['ADMIN', 'USER'],
  },
  {
    path: '/users',
    icon: <Icon component={FaUsers} style={{ fontSize: 20 }} />,
    label: 'Users',
    roles: ['ADMIN'],
  },

  {
    path: '/settings',
    icon: <Icon component={FaCogs} style={{ fontSize: 20 }} />,
    label: 'Settings',
    roles: ['ADMIN', 'USER'],
  },

  {
    path: '/articles',
    icon: <Icon component={IoNewspaper} style={{ fontSize: 20 }} />,
    label: 'Articles',
    roles: ['ADMIN', 'USER'],
  },
  {
    path: '/account-statements',
    icon: <Icon component={FaMoneyBillTransfer} style={{ fontSize: 20 }} />,
    label: 'Account Statements',
    roles: ['ADMIN', 'USER'],
  },

  // {
  //     path: "/",
  //     icon: <Icon component={RiDashboardFill} style={{ fontSize: 20 }} />,
  //     label: "Employer Dashboard",
  //     disabled: true
  // },
  // {
  //     path: "/employee-eligiblity",
  //     icon: <Icon component={BsFillPersonCheckFill} style={{ fontSize: 20 }} />,
  //     label: "Employees Eligiblity",
  //     disabled: true,
  //     roles: ["ADMIN", "USER"]
  // },
  // {
  //     path: "/pay-cycles",
  //     icon: <Icon component={FaRecycle} style={{ fontSize: 20 }} />,
  //     label: "Pay Cycles",
  //     disabled: true,
  //     roles: ["ADMIN", "USER"]
  // },
  // {
  //     path: "/pay-period",
  //     icon: <Icon component={FaWallet} style={{ fontSize: 20 }} />,
  //     label: "Start Pay Period",
  //     disabled: true,
  //     roles: ["ADMIN", "USER"]
  // },
];

export const superAdminMenuConfig = [
  {
    path: '/',
    icon: <Icon component={FaBuilding} style={{ fontSize: 20 }} />,
    label: 'Employers',
  },
  {
    path: '/employees',
    icon: <Icon component={FaUserTie} style={{ fontSize: 20 }} />,
    label: 'Employees',
    disabled: false,
  },
  {
    path: '/transactions',
    icon: <Icon component={BiTransfer} style={{ fontSize: 20 }} />,
    label: 'Advances',
    disabled: false,
  },

  {
    path: '/pay-periods',
    icon: <Icon component={FaRecycle} style={{ fontSize: 20 }} />,
    label: 'Pay Periods',
    disabled: false,
  },
  {
    path: '/payroll-data',
    icon: <Icon component={FaDatabase} style={{ fontSize: 20 }} />,
    label: 'Payroll Data',
  },

  {
    path: '/users',
    icon: <Icon component={FaUsers} style={{ fontSize: 20 }} />,
    label: 'Users',
  },

  {
    path: '/settings',
    icon: <Icon component={FaCogs} style={{ fontSize: 20 }} />,
    label: 'Settings',
    roles: ['ADMIN', 'USER'],
  },

  {
    path: '/articles',
    icon: <Icon component={IoNewspaper} style={{ fontSize: 20 }} />,
    label: 'Articles',
    roles: ['ADMIN', 'USER'],
  },
  {
    path: '/account-statements',
    icon: <Icon component={FaMoneyBillTransfer} style={{ fontSize: 20 }} />,
    label: 'Account Statements',
    roles: ['ADMIN', 'USER'],
  },
  // {
  //     path: "/employer-dashboard",
  //     icon: <Icon component={RiDashboardFill} style={{ fontSize: 20 }} />,
  //     label: "Employer Dashboard",
  //     disabled: true
  // },
  // {
  //     path: "/employee-eligiblity",
  //     icon: <Icon component={BsFillPersonCheckFill} style={{ fontSize: 20 }} />,
  //     label: "Employees Eligiblity",
  //     disabled: true
  // },
  // {
  //     path: "/superadmin-dashboard",
  //     icon: <Icon component={RiDashboardFill} style={{ fontSize: 20 }} />,
  //     label: "SuperAdmin Dashboard",
  //     disabled: true
  // },
  // {
  //     path: "/invoices",
  //     icon: <Icon component={FaFileInvoiceDollar} style={{ fontSize: 20 }} />,
  //     label: "Invoices",
  //     disabled: true
  // },
  // {
  //     path: "/promotions",
  //     icon: <Icon component={FaTicketAlt} style={{ fontSize: 20 }} />,
  //     label: "Promotions",
  //     disabled: true
  // },
  // {
  //     path: "/pay-cycles",
  //     icon: <Icon component={FaRecycle} style={{ fontSize: 20 }} />,
  //     label: "Pay Cycles",
  //     disabled: true
  // },
  // {
  //     path: "/pay-period",
  //     icon: <Icon component={FaWallet} style={{ fontSize: 20 }} />,
  //     label: "Start Pay Period",
  //     disabled: true
  // },
];

export const supportMenuConfig = [
  {
    path: '/',
    icon: <Icon component={FaBuilding} style={{ fontSize: 20 }} />,
    label: 'Employers',
  },
  {
    path: '/employees',
    icon: <Icon component={FaUserTie} style={{ fontSize: 20 }} />,
    label: 'Employees',
  },
  {
    path: '/transactions',
    icon: <Icon component={BiTransfer} style={{ fontSize: 20 }} />,
    label: 'Transactions',
  },
  {
    path: '/promotions',
    icon: <Icon component={FaTicketAlt} style={{ fontSize: 20 }} />,
    label: 'Promotions',
  },
  {
    path: '/pay-cycles',
    icon: <Icon component={FaRecycle} style={{ fontSize: 20 }} />,
    label: 'Pay Cycles',
  },
  {
    path: '/account-statements',
    icon: <Icon component={FaMoneyBillTransfer} style={{ fontSize: 20 }} />,
    label: 'Account Statements',
    roles: ['ADMIN', 'USER'],
  },
];

export const roleBasedConfigMap = {
  EMPLOYER: employerAdminMenuConfig,
  SUPPORT: supportMenuConfig,
  ADVANCIA_ADMIN: superAdminMenuConfig,
};
