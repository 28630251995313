import React from 'react';
import { Button, Popconfirm, Typography } from 'antd';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const DeletePopconfirm = ({
  title = `Delete`,
  description = 'Are you sure you want to delete this item?',
  buttonText = 'Delete',
  onDelete,
  cancelText = 'Cancel',
  okText = 'Delete',
}) => {
  return (
    <Popconfirm
      onConfirm={onDelete}
      placement="topRight"
      cancelText={cancelText}
      okText={okText}
      title={
        <Typography.Text strong style={{ maxWidth: '20rem' }} ellipsis>
          {title}
        </Typography.Text>
      }
      description={description}
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
      <Button danger icon={<DeleteOutlined />}>
        {buttonText}
      </Button>
    </Popconfirm>
  );
};

export default DeletePopconfirm;
