import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_USERS,
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  ADD_USER,
  ADD_USER_LOADING,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  ENABLE_DISABLE_USER,
  ENABLE_DISABLE_USER_LOADING,
  ENABLE_DISABLE_USER_SUCCESS,
  ENABLE_DISABLE_USER_ERROR,

  // invite users
  GET_INVITATION_USERS,
  GET_INVITATION_USERS_LOADING,
  GET_INVITATION_USERS_SUCCESS,
  GET_INVITATION_USERS_ERROR,
  REVOKE_INVITATION_LOADING,
  REVOKE_INVITATION_SUCCESS,
  REVOKE_INVITATION_ERROR,
  REVOKE_INVITATION,
  RESEND_INVITATION,
  RESEND_INVITATION_LOADING,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_ERROR,
  UPDATE_INVITATION,
  UPDATE_INVITATION_LOADING,
  UPDATE_INVITATION_SUCCESS,
  UPDATE_INVITATION_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';
import { message } from 'antd';

function* getUsers({ body }) {
  yield put({ type: GET_USERS_LOADING });
  try {
    const query = {
      number_page: body.page || 1,
      status: body.search,
      employer_id: body.employerId,
      item_per_page: 10,
    };
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_employer_users(query),
    });
    yield put({ type: GET_USERS_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_USERS_ERROR, errorMessage: '' });
  }
}

function* getInvitations({ body }) {
  yield put({ type: GET_INVITATION_USERS_LOADING });
  try {
    const query = {
      page_number: body.page_number || 1,
      status: body.status,
      role: body.role,
      employer_id: body.employerId,
      item_per_page: 10,
    };
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_employer_invitations_users(query),
    });
    yield put({ type: GET_INVITATION_USERS_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_INVITATION_USERS_ERROR, errorMessage: '' });
  }
}

function* createUser({ body }) {
  message.loading({ key: ADD_USER, content: 'Inviting a new user...' });

  const { employerId, onSuccess, onError } = body;
  yield put({ type: ADD_USER_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.employers.create_employer_user({ employer_id: employerId }),
      body,
    });

    yield put({ type: ADD_USER_SUCCESS, data });
    onSuccess && onSuccess();
    message.success({ key: ADD_USER, content: 'User invited successfully!' });
  } catch (error) {
    errorhandling(error);
    yield put({ type: ADD_USER_ERROR, errorMessage: '' });
    onError && onError();
    message.error({ key: ADD_USER, content: 'Failed to invite user' });
  }
}

function* updateUserById({ body }) {
  message.loading({ key: UPDATE_USER, content: 'Updating User...' });
  const { employerId, onSuccess, onError } = body;
  yield put({ type: UPDATE_USER_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'PUT',
      url: apiConfig.employers.update_employer_user(body.id),
      body,
    });
    yield put({ type: UPDATE_USER_SUCCESS, data: data });
    message.success({ key: UPDATE_USER, content: 'User updated successfully!' });
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_USER_ERROR, errorMessage: '' });
    message.error({ key: UPDATE_USER, content: 'Failed to update user' });
    onError && onError();
  }
}

function* updateStatus({ body }) {
  message.loading({ key: ENABLE_DISABLE_USER, content: 'Updating User Status...' });
  const { employerId, ...bodyPayload } = body;
  yield put({ type: ENABLE_DISABLE_USER_LOADING });
  try {
    const endpoint = apiConfig.employers.toggle_employer_user({
      employer_user_id: body.id,
      enable: body.active,
    });
    const { data } = yield call(api, { method: 'POST', url: endpoint, body: bodyPayload });
    yield put({ type: ENABLE_DISABLE_USER_SUCCESS, data: data });
    message.success({ key: ENABLE_DISABLE_USER, content: 'User status updated successfully!' });
  } catch (error) {
    errorhandling(error);
    yield put({ type: ENABLE_DISABLE_USER_ERROR, errorMessage: '' });
    message.error({ key: ENABLE_DISABLE_USER, content: 'Failed to update user status' });
  }
}

function* deleteInvitation({ body }) {
  message.loading({ key: REVOKE_INVITATION, content: 'Revoking invitation...' });
  yield put({ type: REVOKE_INVITATION_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'DELETE',
      url: apiConfig.employers.delete_employer_user_invitation({
        employer_id: body.employerId,
        invitation_id: body.id,
      }),
    });
    yield put({ type: REVOKE_INVITATION_SUCCESS, data: data });
    message.success({ key: REVOKE_INVITATION, content: 'Invitation revoked successfully!' });
  } catch (error) {
    errorhandling(error);
    yield put({ type: REVOKE_INVITATION_ERROR, errorMessage: '' });
    message.error({ key: REVOKE_INVITATION, content: 'Failed to revoke invitation' });
  }
}

function* resendInvitation({ body }) {
  message.loading({ key: RESEND_INVITATION, content: 'Resending invitation...' });
  yield put({ type: RESEND_INVITATION_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.employers.resend_employer_user_invitation({
        employer_id: body.employerId,
        invitation_id: body.id,
      }),
    });
    yield put({ type: RESEND_INVITATION_SUCCESS, data: data });
    message.success({ key: RESEND_INVITATION, content: 'Invitation resent successfully!' });
  } catch (error) {
    errorhandling(error);
    yield put({ type: RESEND_INVITATION_ERROR, errorMessage: '' });
    message.error({ key: RESEND_INVITATION, content: 'Failed to resend invitation' });
  }
}

function* updateInvitation({ body }) {
  const { employerId, onSuccess, onError, ...bodyPayload } = body;
  message.loading({ key: UPDATE_INVITATION, content: 'Updating invitation...' });
  yield put({ type: UPDATE_INVITATION_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'PUT',
      url: apiConfig.employers.update_employer_user_invitation({
        employer_id: employerId,
        invitation_id: body.id,
      }),
      body: bodyPayload,
    });
    yield put({ type: UPDATE_INVITATION_SUCCESS, data: data });
    message.success({ key: UPDATE_INVITATION, content: 'Invitation updated successfully!' });
    onSuccess && onSuccess();
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_INVITATION_ERROR, errorMessage: '' });
    message.error({ key: UPDATE_INVITATION, content: 'Failed to update invitation' });
    onError && onError();
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchUsersSaga() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(GET_INVITATION_USERS, getInvitations);
  yield takeLatest(ADD_USER, createUser);
  yield takeLatest(UPDATE_USER, updateUserById);
  yield takeLatest(ENABLE_DISABLE_USER, updateStatus);
  yield takeLatest(REVOKE_INVITATION, deleteInvitation);
  yield takeLatest(RESEND_INVITATION, resendInvitation);
  yield takeLatest(UPDATE_INVITATION, updateInvitation);
}
