export const STEPS = {
  UPLOAD: 'UPLOAD',
  REVIEW: 'REVIEW',
  COMPLETE: 'COMPLETE',
};

export const steps = [
  { key: STEPS.UPLOAD, title: 'Upload' },
  { key: STEPS.REVIEW, title: 'Review Mapping' },
  { key: STEPS.COMPLETE, title: 'Complete' },
];

export const VALIDATION_BATCH_SIZE = 25;

export const getColumnType = (key) => ({ String: 'Text', Number: 'Number', Date: 'Date' })[key];

export const parserConfig = (processingConfig) => ({
  delimiter: processingConfig?.dilemiter,
  header: processingConfig?.hasHeaderRow,
  skipEmptyLines: true,
  dynamicTyping: true,
  chunkSize: 100,
  transformHeader: (h) => h.trim(),
});

export const FileTypes = {
  EARNINGS: 'EARNINGS',
  DAILY_SHIFT_EARNINGS: 'DAILY_SHIFT_EARNINGS',
  EMPLOYEE_DETAILS: 'EMPLOYEE_DETAILS',
};

export const fuseOptions = (minMatchCharLength = 2) => ({
  includeScore: true,
  minMatchCharLength,
  threshold: 0.6,
  distance: 100,
  ignoreLocation: true,
});

export const ErrorTypes = {
  EMPTY_FILE_ERROR: {
    code: 'File is empty',
    message: 'No data was imported, the file is empty!',
  },
  REQUIRED_FIELD_ERROR: (field, rowIndex = null) => ({
    code: 'Required Field',
    message: `${field} is Required${rowIndex !== null ? ` in Row ${rowIndex + 1}` : ''}`,
  }),
  INVALID_DATE_ERROR: (field, rowIndex = null) => ({
    code: 'Invalid Date Format',
    message: `${field} is Invalid in Row ${rowIndex + 1}`,
  }),
  INVALID_NUMBER_ERROR: (field, rowIndex = null) => ({
    code: 'Invalid Number Format',
    message: `${field} is Invalid in Row ${rowIndex + 1}`,
  }),
};
