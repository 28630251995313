import { call, put, takeLatest } from 'redux-saga/effects';
import {
  VERIFY_TOKEN_LOADING,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_ERROR,
  VERIFY_TOKEN,
  REGISTER_EMPLOYER,
  REGISTER_EMPLOYER_START,
  REGISTER_EMPLOYER_SUCCESS,
  REGISTER_EMPLOYER_ERROR,
  PRE_VERIFY_TOKEN,
  PRE_VERIFY_TOKEN_SUCCESS,
  PRE_VERIFY_TOKEN_ERROR,
  PRE_VERIFY_TOKEN_LOADING,
} from './actions';
import { api } from '../../utils/api';
import { apiConfig } from '../../utils/apiConfig';
import { ErrorMessageToErrorCodeMap } from './constants';
import { message } from 'antd';

function* verifyToken({ body }) {
  yield put({ type: VERIFY_TOKEN_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.invitation.verify_token(),
      body: {
        token: body.token,
      },
      headers: {
        Authorization: JSON.stringify({
          role: 'ADMIN',
          user_id: '1234',
          groups: ['ADVANCIA_ADMIN'],
        }),
      },
    });

    yield put({ type: VERIFY_TOKEN_SUCCESS, data: data });
    body.onSuccess && body.onSuccess(data);
  } catch (error) {
    const errorMessage = error.response?.data?.errors?.[0]?.message || 'Something went wrong';
    const errorCode = ErrorMessageToErrorCodeMap[errorMessage];
    yield put({ type: VERIFY_TOKEN_ERROR, errorMessage: errorCode });
    body.onError && body.onError(error);
  }
}

function* preVerifyToken({ body }) {
  yield put({ type: PRE_VERIFY_TOKEN_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.invitation.pre_verify_token(),
      body: {
        token: body.token,
      },
      headers: {
        Authorization: JSON.stringify({
          role: 'ADMIN',
          user_id: '1234',
          groups: ['ADVANCIA_ADMIN'],
        }),
      },
    });

    yield put({ type: PRE_VERIFY_TOKEN_SUCCESS, data: data });
  } catch (error) {
    const errorMessage = error.response?.data?.errors?.[0]?.message || 'Something went wrong';
    const errorCode = ErrorMessageToErrorCodeMap[errorMessage];
    yield put({ type: PRE_VERIFY_TOKEN_ERROR, errorMessage: errorCode });
  }
}

function* registerEmployer({ body }) {
  message.loading({ key: REGISTER_EMPLOYER, content: 'Verfiying the confirmation code...' });
  yield put({ type: REGISTER_EMPLOYER_START });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.employers.register_employer(),
      body,
      headers: {
        Authorization: JSON.stringify({
          role: 'ADMIN',
          user_id: '1234',
          groups: ['ADVANCIA_ADMIN'],
        }),
      },
    });

    yield put({ type: REGISTER_EMPLOYER_SUCCESS, data: data });
    message.success({ key: REGISTER_EMPLOYER, content: 'Account registered successfully!' });
    body.onSuccess && body.onSuccess(data);
  } catch (error) {
    const errorMessage = error.response?.data?.errors?.[0]?.message || 'Something went wrong';
    const errorCode = ErrorMessageToErrorCodeMap[errorMessage];
    yield put({ type: REGISTER_EMPLOYER_ERROR, errorMessage: errorCode });
    body.onError && body.onError(error);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchInviteSaga() {
  yield takeLatest(VERIFY_TOKEN, verifyToken);
  yield takeLatest(REGISTER_EMPLOYER, registerEmployer);
  yield takeLatest(PRE_VERIFY_TOKEN, preVerifyToken);
}
