import React from 'react';
import { PreviewContextProvider } from './context';
import ImportCSVSteps from './steps';

export default function FileModelCsvUpload({ fileModel, onComplete, fileModelConfig }) {
  return (
    <PreviewContextProvider fileModel={fileModel} fileModelConfig={fileModelConfig}>
      <ImportCSVSteps onComplete={onComplete} />
    </PreviewContextProvider>
  );
}
