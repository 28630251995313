import { put, takeLatest, call } from 'redux-saga/effects';
import * as actions from './actions';
import { message } from 'antd';
import { api } from '../../utils/api';
import { apiConfig } from '../../utils/apiConfig';
import { errorhandling } from '../../utils/helper';
import dayjs from 'dayjs';

function* getAccountStatements({ employerId, isPolling }) {
  if (!isPolling) yield put({ type: actions.GET_ACCOUNT_STATEMENTS_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_account_statements({ employerId }),
    });

    yield put({ type: actions.GET_ACCOUNT_STATEMENTS_SUCCESS, data, isPolling });
  } catch (error) {
    errorhandling(error);
    const errorMsg =
      error.response?.data?.errors?.[0]?.message?.toString() ??
      'Unable to retrieve your account statements at this time';
    yield put({ type: actions.GET_ACCOUNT_STATEMENTS_ERROR, error: errorMsg });
  }
}

const ANT_MSG_KEY = 'acct_stmt_msg_key';
const EXPECTED_BACKEND_DATE_FORMAT = 'DD-MM-YYYY';
/**
 * @param {Object} args
 * @param {dayjs.Dayjs} args.fromDate
 * @param {dayjs.Dayjs} args.toDate
 */
function* generateAccountStatement({ fromDate, toDate, employerId, fileType }) {
  yield put({ type: actions.GENERATE_ACCOUNT_STATEMENT_LOADING });
  message.loading({
    key: ANT_MSG_KEY,
    content: "Please wait, we're processing your request.",
    duration: 0,
  });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.employers.generate_account_statement({
        from: fromDate.format(EXPECTED_BACKEND_DATE_FORMAT),
        to: toDate.format(EXPECTED_BACKEND_DATE_FORMAT),
        employerId: employerId,
        fileType,
      }),
    });
    const payload = {
      accountId: data.accountId,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      status: data.status,
      createdAt: data.createdAt,
      fileName: data.fileName,
      new: data.new,
      message: data.message,
    };
    yield put({ type: actions.GENERATE_ACCOUNT_STATEMENT_SUCCESS, data: payload });
    if (data.new) {
      message.success({
        key: ANT_MSG_KEY,
        content: data.message,
        duration: 5,
      });
    } else {
      message.destroy(ANT_MSG_KEY);
    }
  } catch (error) {
    message.destroy(ANT_MSG_KEY);
    const errorMsg =
      error.response?.data?.errors?.[0]?.message?.toString() ??
      'Unable to generate statement at this time. Please try again later.';
    yield put({ type: actions.GENERATE_ACCOUNT_STATEMENT_ERROR, error: errorMsg });
  }
}

/**
* @param {Object} params
* @param {'copy' | 'download'} params.operation - The action to perform
after getting the signed url for the file
* @param {string} params.fileName
* @param {string} params.employerId
* @param {(url: string, operation: 'copy' | 'download', fileName: string)=>void} params.onSuccess
*/
function* getSignedFileUrl({ operation, fileName, employerId, onSuccess }) {
  yield put({ type: actions.GET_SIGNED_FILE_URL_LOADING, data: { fileName, operation } });
  try {
    const {
      data: { url },
    } = yield call(api, {
      method: 'GET',
      url: apiConfig.employers.get_signed_file_url({ fileName, employerId }),
    });
    onSuccess && onSuccess(url, operation, fileName);
    yield put({ type: actions.GET_SIGNED_FILE_URL_SUCCESS, data: {} });
  } catch (error) {
    errorhandling(error);
    const errorMsg =
      error.response?.data?.errors?.[0]?.message?.toString() ??
      'Unable to retrieve pre-signed URL. Please try again later.';
    yield put({ type: actions.GET_SIGNED_FILE_URL_ERROR, error: errorMsg });
  }
}

export default function* watchAccountStatementSaga() {
  yield takeLatest(actions.GET_ACCOUNT_STATEMENTS, getAccountStatements);
  yield takeLatest(actions.GENERATE_ACCOUNT_STATEMENT, generateAccountStatement);
  yield takeLatest(actions.GET_SIGNED_FILE_URL, getSignedFileUrl);
}
