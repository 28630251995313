import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Space, Typography } from 'antd';

export const ActionModal = ({ open, onClose, onConfirm, action, record }) => {
  const { confirmationConfig } = action;

  if (!confirmationConfig) return null;

  const description =
    typeof confirmationConfig.description === 'function'
      ? confirmationConfig.description(record)
      : confirmationConfig.description;

  return (
    <Modal
      open={open}
      title={confirmationConfig.title}
      onOk={onConfirm}
      onCancel={onClose}
      okText={confirmationConfig.okText || 'Confirm'}
      cancelText={confirmationConfig.cancelText || 'Cancel'}
      okButtonProps={{
        danger: action.danger,
        ...confirmationConfig.okButtonProps,
      }}>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space>
          {confirmationConfig.icon && (
            <span style={{ fontSize: '24px' }}>{confirmationConfig.icon}</span>
          )}
          <Typography.Text strong>{description}</Typography.Text>
        </Space>
      </Space>
    </Modal>
  );
};

ActionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  action: PropTypes.shape({
    danger: PropTypes.bool,
    confirmationConfig: PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.node,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node])
        .isRequired,
      okText: PropTypes.string,
      cancelText: PropTypes.string,
      okButtonProps: PropTypes.object,
    }).isRequired,
  }).isRequired,
  record: PropTypes.object.isRequired,
};
