import { combineReducers } from 'redux';
import {
  GET_ACCOUNT_STATEMENTS_ERROR,
  GET_ACCOUNT_STATEMENTS_LOADING,
  GET_ACCOUNT_STATEMENTS_SUCCESS,
  GENERATE_ACCOUNT_STATEMENT_SUCCESS,
  GENERATE_ACCOUNT_STATEMENT_LOADING,
  GENERATE_ACCOUNT_STATEMENT_ERROR,
  GET_SIGNED_FILE_URL_LOADING,
  GET_SIGNED_FILE_URL_ERROR,
  GET_SIGNED_FILE_URL_SUCCESS,
  RESET_GENERATE_ACCOUNT_STATEMENT_RESPONSE,
} from './actions';

const accountStatementsInitState = {
  loading: false,
  error: null,
  data: [],
  generatingAccountStatement: false,
  accountStatementGenError: null,
  isGettingSignedFile: false,
  signedFileRequestData: {},
  generateAccountStatementResponse: null,
};

const accountStatementsStatusPriorityMap = {
  PENDING: 1,
  PROCESSING: 1,
  COMPLETE: 2,
  FAILED: 2,
};

const compareAccountStatements = (a, b) => {
  const priorityA = accountStatementsStatusPriorityMap[a.status.toUpperCase()];
  const priorityB = accountStatementsStatusPriorityMap[b.status.toUpperCase()];
  if (priorityA !== priorityB) {
    return priorityA - priorityB;
  }
  return new Date(b.dateTo) - new Date(a.dateTo);
};

const accountStatements = (state, action) => {
  if (typeof state === 'undefined') {
    return accountStatementsInitState;
  }
  switch (action.type) {
    case GET_ACCOUNT_STATEMENTS_ERROR: {
      return { ...accountStatementsInitState, error: action.error };
    }
    case GET_ACCOUNT_STATEMENTS_SUCCESS: {
      if (action.isPolling && state.data.length > 0) {
        const dataMap = new Map(action.data.map((item) => [item.fileName, item]));

        const updatedStatements = state.data.map((statement) => {
          if (statement.status === 'PENDING' || statement.status === 'PROCESSING') {
            const updatedStatement = dataMap.get(statement.fileName) || statement;
            if (updatedStatement !== statement && updatedStatement.status === 'COMPLETE') {
              updatedStatement.newStatement = true;
            }
            return updatedStatement;
          }
          return statement;
        });

        return { ...accountStatementsInitState, data: updatedStatements };
      } else {
        action.data.sort(compareAccountStatements);
        return { ...accountStatementsInitState, data: action.data };
      }
    }

    case GET_ACCOUNT_STATEMENTS_LOADING: {
      return { ...accountStatementsInitState, loading: true };
    }
    case GENERATE_ACCOUNT_STATEMENT_SUCCESS: {
      const newStatement = action.data;
      return {
        ...accountStatementsInitState,
        data: newStatement.new ? [newStatement, ...state.data] : state.data,
        generateAccountStatementResponse: newStatement,
      };
    }
    case GENERATE_ACCOUNT_STATEMENT_LOADING: {
      return { ...accountStatementsInitState, generatingAccountStatement: true, data: state.data };
    }
    case RESET_GENERATE_ACCOUNT_STATEMENT_RESPONSE: {
      return { ...state, generateAccountStatementResponse: null };
    }
    case GENERATE_ACCOUNT_STATEMENT_ERROR: {
      return {
        ...accountStatementsInitState,
        accountStatementGenError: action.error,
        data: state.data,
      };
    }
    case GET_SIGNED_FILE_URL_LOADING:
      return { ...state, isGettingSignedFile: true, signedFileRequestData: action.data };
    case GET_SIGNED_FILE_URL_ERROR:
    case GET_SIGNED_FILE_URL_SUCCESS:
      return { ...state, isGettingSignedFile: false, signedFileRequestData: {} };
    default:
      return state;
  }
};

export default combineReducers({
  accountStatements,
});
