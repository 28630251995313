import { combineReducers } from 'redux';
import {
  GET_NOTIFICATION_SETTINGS_ERROR,
  GET_NOTIFICATION_SETTINGS_LOADING,
  GET_NOTIFICATION_SETTINGS_STATE_UPDATE,
  GET_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTING_ERROR,
  UPDATE_NOTIFICATION_SETTING_LOADING,
  UPDATE_NOTIFICATION_SETTING_SUCCESS,
  GET_NEW_LINK_LOADING,
  GET_NEW_LINK_SUCCESS,
  GET_NEW_LINK_ERROR,
} from './actions';

const initState = {
  loading: false,
  data: {},
  error: null,
};

const notificationSettings = (state, action) => {
  if (typeof state === 'undefined') {
    return initState;
  }
  switch (action.type) {
    case GET_NOTIFICATION_SETTINGS_LOADING: {
      return {
        loading: true,
        data: {},
        error: null,
      };
    }
    case GET_NOTIFICATION_SETTINGS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case GET_NOTIFICATION_SETTINGS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: null,
      };
    }
    case GET_NOTIFICATION_SETTINGS_STATE_UPDATE: {
      if (state.loading || !action?.updates) {
        return state;
      }
      const data = { ...state.data };
      Object.entries(action.updates).forEach(([settingsKey, updates]) => {
        updates.forEach((update) => {
          const idx = data?.[settingsKey]?.findIndex((setting) => setting.key === update.key) ?? -1;
          if (idx !== -1) {
            data[settingsKey][idx].enabled = update.enabled;
          }
        });
      });
      return {
        loading: false,
        data,
        error: null,
      };
    }
    default:
      return state;
  }
};

const updateSetting = (state, action) => {
  if (typeof state === 'undefined') {
    return initState;
  }
  switch (action.type) {
    case UPDATE_NOTIFICATION_SETTING_LOADING: {
      return {
        loading: true,
        data: {},
        error: null,
      };
    }
    case UPDATE_NOTIFICATION_SETTING_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case UPDATE_NOTIFICATION_SETTING_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: null,
      };
    }
    default:
      return state;
  }
};

const refreshNotificationSettingsLink = (state, action) => {
  if (typeof state === 'undefined') {
    return initState;
  }
  switch (action.type) {
    case GET_NEW_LINK_LOADING: {
      return {
        loading: true,
        data: {},
        error: null,
      };
    }
    case GET_NEW_LINK_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case GET_NEW_LINK_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  notificationSettings,
  updateSetting,
  refreshNotificationSettingsLink,
});
