export const GET_ACCOUNT_STATEMENTS = 'GET_ACCOUNT_STATEMENTS';
export const GET_ACCOUNT_STATEMENTS_ERROR = 'GET_ACCOUNT_STATEMENTS_ERROR';
export const GET_ACCOUNT_STATEMENTS_SUCCESS = 'GET_ACCOUNT_STATEMENTS_SUCCESS';
export const GET_ACCOUNT_STATEMENTS_LOADING = 'GET_ACCOUNT_STATEMENTS_LOADING';

export const GET_ACCOUNT_STATEMENT_COUNT = 'GET_ACCOUNT_STATEMENT_COUNT';
export const GET_ACCOUNT_STATEMENT_COUNT_LOADING = 'GET_ACCOUNT_STATEMENT_COUNT_LOADING';
export const GET_ACCOUNT_STATEMENT_COUNT_ERROR = 'GET_ACCOUNT_STATEMENT_COUNT_ERROR';
export const GET_ACCOUNT_STATEMENT_COUNT_SUCCESS = 'GET_ACCOUNT_STATEMENT_COUNT_SUCCESS';

export const GENERATE_ACCOUNT_STATEMENT = 'GENERATE_ACCOUNT_STATEMENT';
export const GENERATE_ACCOUNT_STATEMENT_LOADING = 'GENERATE_ACCOUNT_STATEMENT_LOADING';
export const GENERATE_ACCOUNT_STATEMENT_ERROR = 'GENERATE_ACCOUNT_STATEMENT_ERROR';
export const GENERATE_ACCOUNT_STATEMENT_SUCCESS = 'GENERATE_ACCOUNT_STATEMENT_SUCCESS';

export const GET_SIGNED_FILE_URL = 'GET_SIGNED_FILE_URL';
export const GET_SIGNED_FILE_URL_ERROR = 'GET_SIGNED_FILE_URL_ERROR';
export const GET_SIGNED_FILE_URL_LOADING = 'GET_SIGNED_FILE_URL_LOADING';
export const GET_SIGNED_FILE_URL_SUCCESS = 'GET_SIGNED_FILE_URL_SUCCESS';

export const RESET_GENERATE_ACCOUNT_STATEMENT_RESPONSE =
  'RESET_GENERATE_ACCOUNT_STATEMENT_RESPONSE';

export const getAccountStatements = ({ employerId, isPolling }) => ({
  type: GET_ACCOUNT_STATEMENTS,
  employerId,
  isPolling,
});
export const getAccountStatementsCount = () => ({ type: GET_ACCOUNT_STATEMENT_COUNT });
export const generateAccountStatement = ({ fromDate, toDate, employerId, fileType }) => ({
  type: GENERATE_ACCOUNT_STATEMENT,
  fromDate,
  toDate,
  employerId,
  fileType,
});
/**
* @param {Object} params
* @param {'copy' | 'download'} params.operation - The action to perform
after getting the signed url for the file
* @param {string} params.fileName
* @param {string} params.employerId
* @param {(url: string, operation: 'copy' | 'download', fileName: string)=>void} params.onSuccess
*/
export const getSignedFileUrl = ({ operation, fileName, employerId, onSuccess }) => ({
  type: GET_SIGNED_FILE_URL,
  operation,
  fileName,
  employerId,
  onSuccess,
});
export const resetGenerateAccountStatementResponse = () => ({
  type: RESET_GENERATE_ACCOUNT_STATEMENT_RESPONSE,
});
