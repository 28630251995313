export const GET_NOTIFICATION_SETTINGS = 'Get_NOTIFICATION_SETTINGS';
export const GET_NOTIFICATION_SETTINGS_LOADING = 'Get_NOTIFICATION_SETTINGS_LOADING';
export const GET_NOTIFICATION_SETTINGS_SUCCESS = 'Get_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_NOTIFICATION_SETTINGS_ERROR = 'Get_NOTIFICATION_SETTINGS_ERROR';
export const GET_NOTIFICATION_SETTINGS_STATE_UPDATE = 'GET_NOTIFICATION_SETTINGS_STATE_UPDATE';

export const UPDATE_NOTIFICATION_SETTING = 'UPDATE_NOTIFICATION_SETTING';
export const UPDATE_NOTIFICATION_SETTING_LOADING = 'UPDATE_NOTIFICATION_SETTING_LOADING';
export const UPDATE_NOTIFICATION_SETTING_ERROR = 'UPDATE_NOTIFICATION_SETTING_ERROR';
export const UPDATE_NOTIFICATION_SETTING_SUCCESS = 'UPDATE_NOTIFICATION_SETTING_SUCCESS';

export const GET_NEW_LINK = 'GET_NEW_LINK';
export const GET_NEW_LINK_LOADING = 'GET_NEW_LINK_LOADING';
export const GET_NEW_LINK_SUCCESS = 'GET_NEW_LINK_SUCCESS';
export const GET_NEW_LINK_ERROR = 'GET_NEW_LINK_ERROR';

export const getNotificationSettings = (jwt) => ({ type: GET_NOTIFICATION_SETTINGS, jwt });
export const updateNotificationSetting = ({ jwt, updates }) => ({
  type: UPDATE_NOTIFICATION_SETTING,
  updates,
  jwt,
});
export const updateNotificationSettingsState = (updates) => ({
  type: GET_NOTIFICATION_SETTINGS_STATE_UPDATE,
  updates,
});
export const getRefreshedNotificationSettingsLink = (jwt) => ({ type: GET_NEW_LINK, jwt });
