import React, { useState } from 'react';
import { Input } from 'antd';
import { validateMobileNumber } from '../../../utils/validationRules';

/**
 * A custom input component to validate phone number, allowing only london numbers
 */
const PhoneNumberInput = ({
  id,
  value,
  onChange,
  size = 'large',
  style = {},
  ...antInputProps
}) => {
  const [isValid, setIsValid] = useState(true);

  const onUpdate = (value) => {
    const validation = validateMobileNumber(value);

    if (validation.isValid) {
      onChange(validation.phoneNumber);
      setIsValid(true);
    } else {
      onChange(value);
      setIsValid(false);
    }
  };

  return (
    <Input
      id={id}
      size={size}
      style={{ width: '100%', ...style }}
      value={value}
      allowClear
      onChange={(event) => onUpdate(event.target.value)}
      status={isValid ? 'success' : 'error'}
      {...antInputProps}
    />
  );
};

export default PhoneNumberInput;
