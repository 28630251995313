// Enum-like object to define possible invitation-related error codes
export const InvitationErrorCodes = {
  INVALID: 'INVALID_INVITATION',
  EXPIRED: 'EXPIRED_INVITATION',
  NOT_FOUND: 'INVITATION_NOT_FOUND',
  ALREADY_ACCEPTED: 'INVITATION_ALREADY_ACCEPTED',
  UNKNOWN: 'UNKNOWN_INVITATION_ERROR',
  MAX_VERIFICATION_CODE_SENT: 'MAX_VERIFICATION_CODE_SENT',
  VERIFICATION_CODE_INVALID: 'INVALID_VERIFICATION_CODE',
  VERIFICATION_CODE_EXPIRED: 'VERIFICATION_CODE_EXPIRED',
  PASSWORD_POLICY_LOWERCASE: 'PASSWORD_POLICY_LOWERCASE',
  PASSWORD_POLICY_UPPERCASE: 'PASSWORD_POLICY_UPPERCASE',
  PASSWORD_POLICY_SYMBOL: 'PASSWORD_POLICY_SYMBOL',
  PASSWORD_POLICY_NUMERIC: 'PASSWORD_POLICY_NUMERIC',
  TOO_SHORT_PASSWORD: 'TOO_SHORT_PASSWORD',
};

// Mapping of error messages to standardized invitation error codes
export const ErrorMessageToErrorCodeMap = {
  'Token not found': InvitationErrorCodes.INVALID,
  'Invalid Value': InvitationErrorCodes.INVALID,
  'Invalid Token': InvitationErrorCodes.INVALID,
  'Unable to Verify Token': InvitationErrorCodes.INVALID,
  'Token Expired': InvitationErrorCodes.EXPIRED,
  'Invitation Expired': InvitationErrorCodes.EXPIRED,
  'Invitation not found': InvitationErrorCodes.NOT_FOUND,
  'Invitation already accepted': InvitationErrorCodes.ALREADY_ACCEPTED,
  'Something went wrong': InvitationErrorCodes.UNKNOWN,
  // verify invitation errors
  'Maximum number of verification code sent, Retry in 10 min':
    InvitationErrorCodes.MAX_VERIFICATION_CODE_SENT,
  // register employer user errors
  'Invalid Invitation': InvitationErrorCodes.INVALID,
  'Invalid Verification Code': InvitationErrorCodes.VERIFICATION_CODE_INVALID,
  'The number of verification codes sent exceeds the allowed limit':
    InvitationErrorCodes.MAX_VERIFICATION_CODE_SENT,
  'Verification Code expired': InvitationErrorCodes.VERIFICATION_CODE_EXPIRED,
  //password errors
  'Password did not conform with policy: Password must have lowercase characters signup and confirm issue':
    InvitationErrorCodes.PASSWORD_POLICY_LOWERCASE,
  'Password did not conform with policy: Password must have uppercase characters signup and confirm issue':
    InvitationErrorCodes.PASSWORD_POLICY_UPPERCASE,
  'Password did not conform with policy: Password must have symbol characters signup and confirm issue':
    InvitationErrorCodes.PASSWORD_POLICY_SYMBOL,
  'Password did not conform with policy: Password must have numeric characters signup and confirm issue':
    InvitationErrorCodes.PASSWORD_POLICY_NUMERIC,
  'invalid Body': InvitationErrorCodes.TOO_SHORT_PASSWORD,
  //unknown errors
  'Unable To Create New Employer User': InvitationErrorCodes.UNKNOWN,
};

// Mapping of invitation error codes to user-friendly error messages
export const ErrorCodeToUserErrorMessageMap = {
  [InvitationErrorCodes.EXPIRED]:
    'This invitation is no longer valid. This could be because your account details were updated by an administrator, or because your invitation has been cancelled. Please check your inbox for an updated invitation and contact the administrator of your company’s Advancia account if you can’t find anything.',
  [InvitationErrorCodes.NOT_FOUND]:
    'This invitation is no longer valid. This could be because your account details were updated by an administrator, or because your invitation has been cancelled. Please check your inbox for an updated invitation and contact the administrator of your company’s Advancia account if you can’t find anything.',
  [InvitationErrorCodes.INVALID]:
    'This invitation is invalid. Please check the link you received in your email and try again, or contact the administrator of your company’s Advancia account for assistance.',
  [InvitationErrorCodes.ALREADY_ACCEPTED]:
    'This invitation has already been accepted. If you need further access, please contact the administrator of your company’s Advancia account.',
  [InvitationErrorCodes.UNKNOWN]:
    'Something went wrong. Please try again later or contact support if the issue persists.',
  [InvitationErrorCodes.MAX_VERIFICATION_CODE_SENT]:
    'Maximum number of verification code sent, Retry in 10 min',
  [InvitationErrorCodes.VERIFICATION_CODE_INVALID]: 'Invalid confirmation code',
  [InvitationErrorCodes.VERIFICATION_CODE_EXPIRED]: 'Your confirmation code has expired',
  [InvitationErrorCodes.PASSWORD_POLICY_LOWERCASE]:
    'Password must have lowercase characters. Please ensure your password meets the required criteria.',
  [InvitationErrorCodes.PASSWORD_POLICY_UPPERCASE]:
    'Password must have uppercase characters. Please ensure your password meets the required criteria.',
  [InvitationErrorCodes.PASSWORD_POLICY_SYMBOL]:
    'Password must have symbol characters. Please ensure your password meets the required criteria.',
  [InvitationErrorCodes.PASSWORD_POLICY_NUMERIC]:
    'Password must have numeric characters. Please ensure your password meets the required criteria.',
  [InvitationErrorCodes.TOO_SHORT_PASSWORD]:
    'Password must be at least 8 characters long. Please ensure your password meets the required criteria.',
};

export const InvitationSteps = {
  ACCEPT_INVITATION: 'ACCEPT_INVITATION',
  REGISTER_USER: 'REGISTER_USER',
};
