import React from 'react';
import { useSelector } from 'react-redux';
import { EmployerThemeComponent } from '../components';
import { employerThemeSelector } from '../actions';

export default function EmployerTheme() {
  const selectedEmployer = useSelector((state) => state.shared.selectedEmployer);
  const { data: theme, loading } = useSelector(employerThemeSelector);

  return (
    <EmployerThemeComponent selectedEmployer={selectedEmployer} theme={theme} loading={loading} />
  );
}
