import { combineReducers } from 'redux';
import {
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_INVITATION_USERS_LOADING,
  GET_INVITATION_USERS_SUCCESS,
  GET_INVITATION_USERS_ERROR,
  ADD_USER_LOADING,
  UPDATE_USER_LOADING,
  ADD_USER_ERROR,
  UPDATE_USER_ERROR,
  ADD_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  ENABLE_DISABLE_USER_SUCCESS,
  REVOKE_INVITATION_SUCCESS,
  RESEND_INVITATION_SUCCESS,
  UPDATE_INVITATION_SUCCESS,
} from './actions';

const initialStateList = {
  loading: false,
  list: [],
  error: '',
  actionLoading: false,
};

// invitatios Init Stats
const initialStateInvitationUsersList = {
  loading: false,
  list: [],
  error: '',
};

const initialStateObject = {
  loading: false,
  data: {},
  error: '',
};

const users = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateList;
  }
  switch (action.type) {
    case GET_USERS_LOADING: {
      return { loading: true, list: [], error: '' };
    }
    case GET_USERS_SUCCESS: {
      return { loading: false, list: action.data, error: '' };
    }
    case GET_USERS_ERROR: {
      return { loading: false, list: [], error: action.errorMessage };
    }

    case UPDATE_USER_LOADING:
    case ADD_USER_LOADING: {
      return { ...state, actionLoading: true };
    }

    case UPDATE_USER_ERROR:
    case ADD_USER_ERROR: {
      return { ...state, actionLoading: false, error: action.errorMessage };
    }
    case ENABLE_DISABLE_USER_SUCCESS:
    case UPDATE_USER_SUCCESS: {
      const updatedList = state.list.list.map((user) =>
        user.id === action.data.id ? { ...user, ...action.data } : user
      );
      return { ...state, list: { count: state.list.count, list: updatedList } };
    }

    default:
      return state;
  }
};

const invitationUsers = (state, action) => {
  if (typeof state === 'undefined') {
    return initialStateInvitationUsersList;
  }
  switch (action.type) {
    case GET_INVITATION_USERS_LOADING: {
      return { loading: true, list: [], error: '' };
    }
    case GET_INVITATION_USERS_SUCCESS: {
      return { loading: false, list: action.data, error: '' };
    }
    case GET_INVITATION_USERS_ERROR: {
      return { loading: false, list: [], error: action.errorMessage };
    }

    case ADD_USER_SUCCESS: {
      const invit = action.data;
      const list = {
        count: state.list.count + 1,
        list: [...state.list.list, invit],
      };
      return { ...state, list, error: '' };
    }

    case REVOKE_INVITATION_SUCCESS: {
      const list = {
        count: state.list.count - 1,
        list: state.list.list.filter((user) => user.id !== action.data.id),
      };
      return { ...state, list, error: '' };
    }

    case RESEND_INVITATION_SUCCESS:
    case UPDATE_INVITATION_SUCCESS: {
      const updatedList = state.list.list.map((user) =>
        user.id === action.data.id ? { ...user, ...action.data } : user
      );
      return { ...state, list: { count: state.list.count, list: updatedList } };
    }

    default:
      return state;
  }
};

export default combineReducers({
  users,
  invitationUsers,
});
