import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { steps } from './constants';

const initialState = {
  fileModel: { value: null, processingConfig: {}, columnsConfig: { list: [], byTargetField: {} } },
  step: 0,
  inputFile: null,
  errors: [],
  rawData: [],
  fileColumns: [],
  columnMapping: {},
};

const PreviewContext = createContext({
  fileModel: initialState.fileModel,
  step: {
    currentIndex: initialState.step,
    currentKey: steps[initialState.step].key,
    goNext: () => {},
    goPrev: () => {},
  },
  inputFile: { value: initialState.inputFile, set: () => {} },
  errors: { list: initialState.errors, set: () => {}, reset: () => {} },
  rawData: { list: initialState.rawData, set: () => {} },
  fileColumns: { list: initialState.fileColumns, set: () => {} },
  columnMapping: { value: initialState.columnMapping, set: () => {} },
  fileModelConfig: {},
});
const usePreviewContext = () => useContext(PreviewContext);

function PreviewContextProvider({ children, fileModel, fileModelConfig }) {
  const [currentStep, setCurrentStep] = useState(initialState.step);
  const [inputFile, setInputFile] = useState(initialState.inputFile);
  const [errors, setErrors] = useState(initialState.errors);
  const [rawData, setRawData] = useState(initialState.rawData);
  const [fileColumns, setFileColumns] = useState(initialState.fileColumns);
  const [columnMapping, setColumnMapping] = useState(initialState.columnMapping);

  const step = {
    currentIndex: currentStep,
    currentKey: steps[currentStep].key,
    goPrev: () => {
      setErrors(initialState.errors); // reset errors when going back
      setCurrentStep((prev) => Math.max(prev - 1, 0));
    },
    goNext: () => setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1)),
  };

  const fileModelValue = useMemo(() => {
    if (!fileModel) return initialState.fileModel;

    return {
      value: fileModel,
      processingConfig: fileModel.processingConfig,
      columnsConfig: {
        list: fileModel.columns,
        byTargetField: fileModel?.columns.reduce((acc, col) => {
          acc[col.targetField] = col;
          return acc;
        }, {}),
      },
    };
  }, [fileModel]);

  // reset state on file change
  useEffect(() => {
    setCurrentStep(initialState.step);
    setErrors(initialState.errors);
    setRawData(initialState.rawData);
    setFileColumns(initialState.fileColumns);
    setColumnMapping(initialState.columnMapping);
  }, [inputFile]);

  // reset file state on file model change
  useEffect(() => {
    setInputFile(initialState.inputFile);
  }, [fileModel]);

  return (
    <PreviewContext.Provider
      value={{
        fileModel: fileModelValue,
        step,
        inputFile: { value: inputFile, set: setInputFile },
        errors: { list: errors, set: setErrors, reset: () => setErrors(initialState.errors) },
        rawData: { list: rawData, set: setRawData },
        fileColumns: { list: fileColumns, set: setFileColumns },
        columnMapping: { value: columnMapping, set: setColumnMapping },
        fileModelConfig,
      }}
      children={children}
    />
  );
}

export { PreviewContextProvider, usePreviewContext };
