import React from 'react';
import { App, Button, Drawer } from 'antd';
import { FileModelCsvUpload } from '../../../shared';
import { useSelector } from 'react-redux';

export default function PreviewDrawer({ isOpen, fileModel, onCancel }) {
  const { data: config, loading: loadingConfig } = useSelector((state) => state.fileModels.config);
  const { message } = App.useApp();
  const onComplete = ({ total }) => {
    message.success(`Successfully processed ${total} records`);
  };
  return (
    <Drawer
      open={isOpen}
      onClose={onCancel}
      title={`Preview "${fileModel?.name}" model`}
      width="50rem"
      maskClosable={false}
      loading={loadingConfig}
      destroyOnClose
      extra={<Button onClick={onCancel}>Cancel</Button>}>
      {fileModel && (
        <FileModelCsvUpload
          fileModel={fileModel}
          onComplete={onComplete}
          fileModelConfig={config}
        />
      )}
    </Drawer>
  );
}
