import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Tooltip, Space } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { ActionModal } from './ActionModal';

const ActionMenu = ({ actions, record, trigger = ['click'], placement = 'bottomRight' }) => {
  const [activeAction, setActiveAction] = useState(null);

  const handleMenuClick = (action) => {
    if (typeof action.disabled === 'function' && action.disabled(record)) {
      return;
    }

    if (action.disabled) {
      return;
    }

    if (action.requireConfirmation) {
      setActiveAction(action);
    } else {
      action.onClick(record);
    }
  };

  const handleModalClose = () => {
    setActiveAction(null);
  };

  const handleModalConfirm = () => {
    if (activeAction) {
      activeAction.onClick(record);
      setActiveAction(null);
    }
  };

  const getMenuItems = () => {
    return actions.map((action) => {
      const isDisabled =
        typeof action.disabled === 'function' ? action.disabled(record) : action.disabled;

      const tooltipText =
        typeof action.disabledTooltip === 'function'
          ? action.disabledTooltip(record)
          : action.disabledTooltip;

      const label =
        isDisabled && tooltipText ? (
          <Tooltip title={tooltipText} placement="left">
            <span
              style={{
                color: isDisabled ? '#00000040' : 'inherit',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}>
              {action.label}
            </span>
          </Tooltip>
        ) : (
          action.label
        );

      return {
        key: action.key,
        label,
        icon:
          action.icon &&
          React.cloneElement(action.icon, {
            style: { color: isDisabled ? '#00000040' : 'inherit' },
          }),
        danger: action.danger,
        disabled: isDisabled,
        onClick: () => handleMenuClick(action),
      };
    });
  };

  return (
    <Space>
      <Dropdown menu={{ items: getMenuItems() }} placement={placement} trigger={trigger}>
        <Button type="text" icon={<MoreOutlined />} />
      </Dropdown>

      {activeAction && (
        <ActionModal
          open={true}
          onClose={handleModalClose}
          onConfirm={handleModalConfirm}
          action={activeAction}
          record={record}
        />
      )}
    </Space>
  );
};

ActionMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      danger: PropTypes.bool,
      disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      disabledTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      requireConfirmation: PropTypes.bool,
      confirmationConfig: PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.node,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node])
          .isRequired,
        okText: PropTypes.string,
        cancelText: PropTypes.string,
        okButtonProps: PropTypes.object,
      }),
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  record: PropTypes.object.isRequired,
  trigger: PropTypes.arrayOf(PropTypes.oneOf(['click', 'hover'])),
  placement: PropTypes.oneOf(['bottomLeft', 'bottomRight', 'topLeft', 'topRight']),
};

export default ActionMenu;
