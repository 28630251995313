import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { api } from '../../utils/api';
import { apiConfig } from '../../utils/apiConfig';
import { errorhandling } from '../../utils/helper';
import { message } from 'antd';

function* getNotificationSettings({ jwt }) {
  yield put({ type: actions.GET_NOTIFICATION_SETTINGS_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'GET',
      url: apiConfig.optOutConfig.get_opt_out_config(jwt),
    });
    yield put({
      type: actions.GET_NOTIFICATION_SETTINGS_SUCCESS,
      data: data,
    });
  } catch (error) {
    const errorMessage = error.response?.data?.errors || 'something went wrong';
    yield put({
      type: actions.GET_NOTIFICATION_SETTINGS_ERROR,
      errorMessage: errorMessage,
    });
  }
}

function* updateNotificationSetting({ updates, jwt }) {
  yield put({ type: actions.UPDATE_NOTIFICATION_SETTING_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'PUT',
      url: apiConfig.optOutConfig.update_opt_out_config(jwt),
      body: updates,
    });
    yield put({ type: actions.UPDATE_NOTIFICATION_SETTING_SUCCESS, data: { updates: data } });
    message.success({ content: 'Email settings updated successfully!' });
  } catch (error) {
    errorhandling(error);
    yield put({
      type: actions.UPDATE_NOTIFICATION_SETTING_ERROR,
      errorMessage:
        error.response?.data?.errors?.[0]?.message ??
        'Error updating notification configuration(s)',
    });
  }
}

function* refreshNotificationSettingsLink({ jwt }) {
  yield put({ type: actions.GET_NEW_LINK_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.optOutConfig.get_refreshed_settings_link(jwt),
    });
    yield put({ type: actions.GET_NEW_LINK_SUCCESS, data });
  } catch (error) {
    errorhandling(error);
    const errorMessage = error.response?.data?.errors?.[0]?.message?.message
      ? 'Error refreshing notification settings link'
      : (error.response?.data?.errors?.[0]?.message ??
        'Error refreshing notification settings link');
    console.log('ERROR: ', error.response?.data);
    yield put({
      type: actions.GET_NEW_LINK_ERROR,
      errorMessage,
    });
  }
}

export default function* watchGetNotificationSage() {
  yield takeLatest(actions.GET_NOTIFICATION_SETTINGS, getNotificationSettings);
  yield takeLatest(actions.UPDATE_NOTIFICATION_SETTING, updateNotificationSetting);
  yield takeLatest(actions.GET_NEW_LINK, refreshNotificationSettingsLink);
}
