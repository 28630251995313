import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  INVITE,
  INVITE_LOADING,
  INVITE_SUCCESS,
  INVITE_ERROR,
  UPDATE_USER_ATTRIBUTES,
  UPDATE_USER_ATTRIBUTES_LOADING,
  UPDATE_USER_ATTRIBUTES_SUCCESS,
  UPDATE_USER_ATTRIBUTES_ERROR,
  SAVE_USER_DETAILS,
  LOGOUT,
  AUTH_CHALLENGE_SET,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { SET_SELECTED_EMPLOYER } from '../../shared/actions';
import { message } from 'antd';
import { jwtDecode } from 'jwt-decode';
import { apiConfig } from '../../utils/apiConfig';
import { SET_EMPLOYER_THEME } from '../employerTheme/actions';
import { getThemeDataFromEmployer } from '../employerTheme/saga';

function* login({ body }) {
  yield put({ type: LOGIN_LOADING });
  let data;
  try {
    if (body.session) {
      const result = yield call(api, {
        method: 'POST',
        url: apiConfig.baseURL + apiConfig.mfa.confirm_mfa_login(),
        serverless: true,
        body: {
          session: body.session,
          code: body.code,
          username: body.username,
        },
      });
      data = result.data;
    } else {
      const result = yield call(api, {
        method: 'POST',
        url: apiConfig.baseURL + apiConfig.auth.signin(),
        serverless: true,
        body,
      });
      data = result.data;
    }

    if (data.session) {
      return yield put({ type: AUTH_CHALLENGE_SET, data: data });
    }

    let userDetails = jwtDecode(data.AuthenticationResult.IdToken);
    const group = userDetails['cognito:groups'][0];
    userDetails['custom:UserType'] = group;
    let authrozation;
    const ENV = process.env.REACT_APP_ENV;

    authrozation = ['dev', 'local'].includes(ENV)
      ? JSON.stringify({
          role: userDetails['custom:role'],
          user_id: userDetails['custom:user_id'],
          employerId: userDetails['cognito:groups'].includes('EMPLOYER')
            ? userDetails['custom:employerId']
            : undefined,
          groups: userDetails['cognito:groups'],
        })
      : data.AuthenticationResult.IdToken;

    if (['ADVANCIA_ADMIN', 'EMPLOYER'].indexOf(group) === -1) {
      yield put({ type: LOGIN_ERROR, errorMessage: '' });
      message.error("You don't have access to admin portal!");
    } else {
      if (group === 'EMPLOYER') {
        const { data: employerUserDetails } = yield call(api, {
          method: 'GET',
          url:
            apiConfig.baseURL +
            apiConfig.employers.get_employer_user(userDetails['custom:user_id']),
          headers: {
            Authorization: authrozation,
          },
        });
        if (employerUserDetails?.approvalStatus !== 'ENABLED') {
          yield put({ type: LOGIN_ERROR, errorMessage: '' });
          message.error(
            'Your account is not active, please contact your employer to activate your account'
          );
          return;
        } else {
          const { data: employerDetails } = yield call(api, {
            method: 'GET',
            url:
              apiConfig.baseURL +
              apiConfig.employers.get_employer({
                employerId: userDetails['custom:employerId'],
              }),
            headers: {
              Authorization: authrozation,
            },
          });
          yield put({ type: SET_SELECTED_EMPLOYER, data: employerDetails });
          // store the employer theme in state
          yield put({ type: SET_EMPLOYER_THEME, data: getThemeDataFromEmployer(employerDetails) });
        }
      }
      yield put({ type: SAVE_USER_DETAILS, data: userDetails });
      yield put({ type: LOGIN_SUCCESS, data: data.AuthenticationResult });
    }
  } catch (error) {
    errorhandling(error);
    yield put({ type: LOGIN_ERROR, errorMessage: '' });
  }
}

function* invite({ body }) {
  yield put({ type: INVITE_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.baseURL + 'auth/signin',
      body,
      serverless: true,
    });
    yield put({ type: INVITE_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: INVITE_ERROR, errorMessage: '' });
  }
}

function* updateUserAttributes({ body }) {
  yield put({ type: UPDATE_USER_ATTRIBUTES_LOADING });
  try {
    const { data } = yield call(api, {
      method: 'POST',
      url: apiConfig.baseURL + 'auth/adminUpdateUserAttributes',
      body: JSON.stringify(body),
      serverless: true,
    });
    yield put({ type: UPDATE_USER_ATTRIBUTES_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: UPDATE_USER_ATTRIBUTES_ERROR, errorMessage: '' });
  }
}

function* logout({ body }) {
  try {
    yield call(api, {
      method: 'POST',
      url: apiConfig.baseURL + apiConfig.auth.signout(),
      body,
      serverless: true,
    });
  } catch (error) {}
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchLoginSaga() {
  yield takeLatest(LOGIN, login);
  yield takeLatest(INVITE, invite);
  yield takeLatest(LOGOUT, logout);
  yield takeLatest(UPDATE_USER_ATTRIBUTES, updateUserAttributes);
}
