import { combineReducers } from 'redux';
import {
  // LOGIN_LOADING,
  // LOGIN_SUCCESS,
  // LOGIN_ERROR,

  // INVITE_LOADING,
  // INVITE_SUCCESS,
  // REFRESH_INVITE,
  // INVITE_ERROR,

  // SAVE_USER_DETAILS,

  // VERIFY_TOKEN,
  VERIFY_TOKEN_LOADING,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_ERROR,

  //
  REGISTER_EMPLOYER_START,
  REGISTER_EMPLOYER_SUCCESS,
  REGISTER_EMPLOYER_ERROR,
  RESET_EMPLOYER_SUCCESS,
  PRE_VERIFY_TOKEN_SUCCESS,
  PRE_VERIFY_TOKEN_ERROR,
  PRE_VERIFY_TOKEN_LOADING,
} from './actions';

const initialStateObject = {
  loading: false,
  data: {},
  error: '',
  validToken: false,
  preLoading: false,
};

const employerStateObject = {
  loading: false,
  data: {},
  error: '',
};

const preVerifyToken = (state = {}, action) => {
  if (typeof state === 'undefined') {
    return { loading: false, data: {}, error: '' };
  }
  switch (action.type) {
    case PRE_VERIFY_TOKEN_LOADING: {
      return { ...state, loading: true, data: {}, error: '' };
    }

    case PRE_VERIFY_TOKEN_SUCCESS: {
      return { ...state, loading: false, data: action.data, error: '' };
    }

    case PRE_VERIFY_TOKEN_ERROR: {
      return { ...state, loading: false, data: {}, error: action.errorMessage };
    }
    default:
      return state;
  }
};

const verifyToken = (state = {}, action) => {
  // if undefined, return initialState
  if (typeof state === 'undefined') {
    return initialStateObject;
  }
  switch (action.type) {
    case VERIFY_TOKEN_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: '',
        validToken: true,
      };
    }
    case VERIFY_TOKEN_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
        validToken: true,
      };
    }
    case VERIFY_TOKEN_LOADING: {
      return {
        loading: true,
        data: {},
        error: '',
        validToken: true,
      };
    }
    default:
      return state;
  }
};

const employerDetails = (state = {}, action) => {
  // if undefined, return initialState
  if (typeof state === 'undefined') {
    return employerStateObject;
  }
  switch (action.type) {
    case REGISTER_EMPLOYER_SUCCESS: {
      return { loading: false, data: action.data, error: '' };
    }
    case REGISTER_EMPLOYER_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    case REGISTER_EMPLOYER_START: {
      return { loading: true, data: {}, error: '' };
    }
    case RESET_EMPLOYER_SUCCESS: {
      return { loading: false, data: {}, error: '' };
    }
    default:
      return state;
  }
};

export default combineReducers({
  preVerifyToken,
  verifyToken,
  employerDetails,
});
